import {
    CreateMedicalInstitutionForm
} from './ui-components';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Flex, Message } from "@aws-amplify/ui-react";
import React from 'react';

import { MedicalInstitutionCreateRequest, MedicalInstitutionCreateRequestType, createMedicalInstitutionsAsync } from './api/medicalInstitutions';

import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function CreateMedicalInstitution() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ formData, setFormData ] = useState<MedicalInstitutionCreateRequestType>({name: '', code: ''});
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ submitting, setSubmitting ] = useState(false);

  const createAndNavigate = async () => {
    setSubmitting(true);
    const result = await createMedicalInstitutionsAsync({'name': formData.name, 'medical_institution_code': formData.code});
    if (!result.error) {
      navigate('./../../');
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    validate(formData);
  }, []);

  /** バリデーションを実行する。 */
  const validate = (data: MedicalInstitutionCreateRequestType) => {
    const result = MedicalInstitutionCreateRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map((x) => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   * 
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dataToValidate = formData;

    switch (e.target.id) {
      case 'name':
        setFormData({ ...formData, name: e.target.value });
        dataToValidate.name = e.target.value;
        break;
      case 'code':
        setFormData({ ...formData, code: e.target.value });
        dataToValidate.code = e.target.value;
    }

    validate(dataToValidate);
  };

  return (
    <Flex direction="column" alignItems="center">
      <CreateMedicalInstitutionForm overrides={{
        Header: {
          children: t('医療機関情報')
        },
        Name: {
          id: 'name',
          value: formData.name,
          label: t('医療機関名'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Code: {
          id: 'code',
          value: formData.code,
          label: t('医療機関コード'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Button: {
          onClick: createAndNavigate,
          children: t('医療機関の登録'),
          isDisabled: errors.length > 0 || submitting
        }
      }}/>
      {
        errors.length > 0
          ? errors.map((error: string, index: number) => <Message key={index} colorTheme="warning">{error}</Message>) 
          : <></>
      }
    </Flex>
  );
}

export default CreateMedicalInstitution;