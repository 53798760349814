import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { Amplify } from "aws-amplify";
import './i18n';
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";
import Modal from 'react-modal';

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);
Modal.setAppElement(rootElement);
root.render(
  <React.StrictMode>
    <AmplifyProvider><Authenticator.Provider><App />
      </Authenticator.Provider>
    </AmplifyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
