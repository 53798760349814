import {
    CreateMedicalInstitutionForm
} from './ui-components';
import { useState, useEffect } from 'react';
import { Flex, Loader, Message } from "@aws-amplify/ui-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import React from 'react';
import { MedicalInstitutionUpdateRequest, MedicalInstitutionUpdateRequestType, getMedicalInstitutionAsync, updateMedicalInstitutionsAsync } from './api/medicalInstitutions';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function EditMedicalInstitution() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const [ formData, setFormData ] = useState<MedicalInstitutionUpdateRequestType>({name: '', code: ''});
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ submitting, setSubmitting ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  if (!id) {
    throw new Error("id is required.");
  }

  const updateAndNavigate = async () => {
    setSubmitting(true);
    const result = await updateMedicalInstitutionsAsync(id, {'name': formData.name, 'medical_institution_code': formData.code});
    if (!result.error) {
      navigate('./../', { state: { medicalInstitution: {id: id, name: formData.name, medical_institution_code: formData.code} } });
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  // stateが取得できる場合はそこから医療機関情報を取得、できない場合はgetMedicalInstitutionAsyncで取得してくる。
  // URLで直接アクセスしたときなどはstateを取得できない。
  useEffect(() => {
    if (!state) {
      setLoading(true);
      getMedicalInstitutionAsync(id).then((response) => {
        if (!response.medicalInstitution) {
          alert(t(getErrorMessage(response.error)));
          navigate('./../../');
        } else {
          const data = {...formData, name: response.medicalInstitution.name, code: response.medicalInstitution.medical_institution_code};
          setFormData(data);
          setLoading(false);
          validate(data);
        }
      });
    } else {
      const { medicalInstitution } = state;
      const data = {...formData, name: medicalInstitution.name, code: medicalInstitution.medical_institution_code};
      setFormData(data);
      validate(data);
    }
  }, []);

  /** バリデーションを実行する。 */
  const validate = (data: MedicalInstitutionUpdateRequestType) => {
    const result = MedicalInstitutionUpdateRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map((x) => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   * 
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dataToValidate = formData;

    switch (e.target.id) {
      case 'name':
        setFormData({...formData, name: e.target.value });
        dataToValidate.name = e.target.value;
        break;
      case 'code':
        setFormData({...formData, code: e.target.value});
        dataToValidate.code = e.target.value;
    }

    validate(dataToValidate);
  };

  return (
    <Flex direction="column" alignItems="center">
      {
        loading
        ?
          <Loader size="large" />
        :
          <></>
      }
      <CreateMedicalInstitutionForm overrides={{
        Header: {
          children: t('医療機関情報')
        },
        Name: {
          id: 'name',
          value: formData.name,
          label: t('医療機関名'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Code: {
          id: 'code',
          value: formData.code,
          label: t('医療機関コード'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Button: {
          onClick: updateAndNavigate,
          children: t('医療機関の登録'),
          isDisabled: loading || errors.length > 0 || submitting
        }
      }}/>
      {
        errors.length > 0
          ? errors.map((error: string, index: number) => <Message key={index} colorTheme="warning">{error}</Message>)
          : <></>
      }
    </Flex>
  );
}

export default EditMedicalInstitution;