import {
  ApproverCardCollection
} from './ui-components';
import { useState, useEffect } from 'react';
import { Button, Flex, Loader, Heading } from "@aws-amplify/ui-react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import React from 'react';
import Modal from 'react-modal';
import { deleteMedicalInstitutionsAsync, getMedicalInstitutionAsync } from './api/medicalInstitutions';
import { Approver, listApproversAsync } from './api/approvers';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function ShowMedicalInstitution() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = useParams();
  const medicalInsitutionId = id;

  if (!medicalInsitutionId) {
    throw new Error("medicalInsitutionId is required.");
  }

  const [medicalInstitutionCode, setMedicalInstitutionCode] = useState<string | undefined>();
  const [medicalInstitutionName, setMedicalInstitutionName] = useState<string | undefined>();
  const [items, setItems] = useState<Approver[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const navigate = useNavigate();

  // stateが取得できる場合はそこから医療機関情報を取得、できない場合はgetMedicalInstitutionAsyncで取得してくる。
  // URLで直接アクセスしたときなどはstateを取得できない。
  useEffect(() => {
    if (!state) {
      getMedicalInstitutionAsync(medicalInsitutionId).then((response) => {
        if (!response.medicalInstitution) {
          alert(t(getErrorMessage(response.error)));
        } else {
          setMedicalInstitutionCode(response.medicalInstitution.medical_institution_code);
          setMedicalInstitutionName(response.medicalInstitution.name);
        }
      });
    } else {
      const { medicalInstitution } = state;
      setMedicalInstitutionCode(medicalInstitution.medical_institution_code);
      setMedicalInstitutionName(medicalInstitution.name);
    }
  }, []);

  useEffect(() => {
    listApproversAsync(medicalInsitutionId)
      .then((response) => {
        if (!response.approvers) {
          alert(t(getErrorMessage(response.error)));
        } else {
          setItems(response.approvers);
          setIsLoading(false);
        }
      });
  }, []);

  const deleteAndNavigate = async () => {
    const response = await deleteMedicalInstitutionsAsync(medicalInsitutionId);
    if (!response.error) {
      navigate('/'); // 成功時、トップに戻る
    } else {
      alert(t(getErrorMessage(response.error)));
    }
  };

  return (
    <Flex direction="column" alignItems="center">
      {
        !medicalInstitutionName
          ?
          <Loader size="large" />
          :
          <Heading level={1}>{medicalInstitutionName}</Heading>
      }
      <Button variation='primary'
        style={{ marginLeft: "auto" }}
        colorTheme='error'
        disabled={medicalInstitutionName === undefined}
        onClick={() => setModalOpened(true)}>{t('医療機関を削除する')}</Button>
      <Flex direction="row" gap="1rem">
        <Button variation="link" onClick={() => navigate('./edit', { state: { medicalInstitution: { id: medicalInsitutionId, name: medicalInstitutionName, medical_institution_code: medicalInstitutionCode } } })}>{t('医療機関の登録情報を変更する')}</Button>
        <Button variation="link" onClick={() => navigate('./medical_specialties', { state: { medicalInstitution: { id: medicalInsitutionId, name: medicalInstitutionName } } })}>{t('診療科情報')}</Button>
        <Button variation="link" onClick={() => navigate('./approvers/new', { state: { medicalInstitution: { id: medicalInsitutionId, name: medicalInstitutionName } } })}>{t('承認者を登録する')}</Button>
        <Button variation="link" onClick={() => navigate('./certificates', { state: { medicalInstitution: { id: medicalInsitutionId, name: medicalInstitutionName } } })}>{t('証明書の一覧・発行')}</Button>
      </Flex>
      <Modal
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
        style={{
          overlay: {},
          content: {
            top: '40%',
            left: '40%',
            right: 'auto',
            bottom: 'auto',
          }
        }}
      >
        <Flex direction="column" gap="3em">
          <Heading>{t('「name」を本当に削除しますか？', {name: medicalInstitutionName})}</Heading>
          <Flex direction="row" gap="3em">
            <Button variation="primary" onClick={() => setModalOpened(false)}>{t('キャンセル')}</Button>
            <Button variation="destructive" onClick={deleteAndNavigate}>{t('削除する')}</Button>
          </Flex>
        </Flex>
      </Modal>
      {
        isLoading
          ? <Loader size="large" />
          : <ApproverCardCollection items={items} overrideItems={({
            item
          }) => ({
            overrides: {
              Button: {
                children: t('編集'),
                onClick:
                  () => navigate(`./approvers/${item.id}/edit`, { state: { approver: item } })
              },
              Name: {
                children: item.name
              }
            }
          })} />
      }
    </Flex>
  );
}

export default ShowMedicalInstitution;