import {
  MedicalSpecialtyCardCollection
} from './ui-components';
import { useState, useEffect } from 'react';
import { Flex, Heading, Button, Loader } from "@aws-amplify/ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { MedicalSpecialty, getMedicalSpecialtiesAsync } from './api/medicalSpecialties';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function MedicalSpecialties() {
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();

  // 診療科一覧の読込中か？
  const [isLoading, setIsLoading] = useState(true);

  const [medicalSpecialties, setMedicalSpecialties] = useState<MedicalSpecialty[]>([]);

  if (!id) {
    throw 'error';
  }

  useEffect(() => {
    getMedicalSpecialtiesAsync(id).then((response) => {
      if (!response.medicalSpecialties) {
        alert(t(getErrorMessage(response.error)));
      } else {
        setMedicalSpecialties(response.medicalSpecialties);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Flex direction="column" alignItems="center">
      <Heading level={1}>{t('診療科一覧')}</Heading>

      <Button variation="link" onClick={() => navigate('./new')}>
        {t('診療科の作成')}
      </Button>
      {
        isLoading
          ? <Loader size="large" />
          : <MedicalSpecialtyCardCollection items={medicalSpecialties} overrideItems={({
            item
          }) => ({
            overrides: {
              Button: {
                onClick: () => { navigate(`./${item.id}/edit`); },
                children: t('編集')
              },
              Name: {
                children: <>{item.name}</>
              }
            }
          })} />
      }
    </Flex>
  );
}

export default MedicalSpecialties;