import {
    MedicalInstitutionCardCollection
} from './ui-components';
import { useState, useEffect } from 'react';
import { Button, Flex, Loader } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import React from 'react';
import { MedicalInstitution, listMedicalInstitutionsAsync } from './api/medicalInstitutions';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function ListMedicalInstitutions() {
  const { t } = useTranslation();

  const [items, setItems] = useState<MedicalInstitution[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    listMedicalInstitutionsAsync()
    .then((response) => {
      if (!response.medicalInstitutions) {
        alert(t(getErrorMessage(response.error)));
      } else {
        setItems(response.medicalInstitutions);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <Flex direction="column" alignItems="center">
      <Button variation="link" onClick={() => navigate('/medical_institutions/new')}>
        {t('医療機関を登録する')}
      </Button>
      {
        isLoading
            ? <Loader size="large" />
            : <MedicalInstitutionCardCollection items={items} overrideItems={({
          item
        }: any) => ({
                overrides: {
                  Code: {
                    children: item.medical_institution_code
                  },
                  Button: {
                    children: t('編集'),
                    onClick:
                      () => navigate(`/medical_institutions/${item.id}`, { state: { medicalInstitution: item } } )
                  },
                  Name: {
                    children: item.name
                  }
                }
            })} />
      }
    </Flex>
  );
}

export default ListMedicalInstitutions;