import { BASE_URL, createHeadersAsync, createHeadersWithNoContentAsync, getErrorCode } from "../constants";
import { z } from 'zod';
import i18next from '../i18n';

export type MedicalInstitution = {
  id: number,
  medical_institution_code: string,
  name: string
}

type GetMedicalInstitutionResult = {
  medicalInstitution: MedicalInstitution,
  error?: undefined;
} | {
  medicalInstitution?: undefined;
  error: string
}

export const MedicalInstitutionUpdateRequest = z.object({
  name: z.string({required_error: i18next.t('医療機関名は必須です。')})
    .min(1, {message: i18next.t('医療機関名は1文字以上である必要があります。')})
    .max(64, {message: i18next.t('医療機関名は64文字以下である必要があります。')}),
  code: z.string({required_error: i18next.t("医療機関コードは必須です。")})
    .regex(RegExp('^[0-9]{7}$'), i18next.t('医療機関コードは7桁の数字である必要があります。'))
});

export const MedicalInstitutionCreateRequest = MedicalInstitutionUpdateRequest;

export type CreateMedicalInstitutionsResult = GetMedicalInstitutionResult;
export type UpdateMedicalInstitutionResult = GetMedicalInstitutionResult;

export type MedicalInstitutionUpdateRequestType = z.infer<typeof MedicalInstitutionUpdateRequest>;
export type MedicalInstitutionCreateRequestType = z.infer<typeof MedicalInstitutionCreateRequest>;

export type MedicalInstitutionCreate = {
  name: string, 
  medical_institution_code: string
};

export async function createMedicalInstitutionsAsync(body: MedicalInstitutionCreate): Promise<CreateMedicalInstitutionsResult> {
  return fetch(`${BASE_URL}/medical_institutions`, {
    headers: await createHeadersAsync(),
    method: 'POST',
    body: JSON.stringify(body)
  })
  .then(async (response) => {
    if (!response.ok) {
      throw await getErrorCode(response);
    }
    return response.json();
  })
  .catch((error) => {
    return {error: error};
  });
}

export async function getMedicalInstitutionAsync(medical_institution_id: string): Promise<GetMedicalInstitutionResult> {
  return fetch(`${BASE_URL}/medical_institutions/${medical_institution_id}`, {
    headers: await createHeadersWithNoContentAsync(),
    method: 'GET'
  })
  .then(async (response) => {
    if (!response.ok) {
      throw await getErrorCode(response);
    }
    return response.json() as Promise<MedicalInstitution>;
  })
  .then((json) => {
    return {medicalInstitution: json}
  })
  .catch((error) => {
    return {error: error};
  });
};

export async function updateMedicalInstitutionsAsync(id: string, body: MedicalInstitutionCreate): Promise<UpdateMedicalInstitutionResult> {
  return fetch(`${BASE_URL}/medical_institutions/${id}`, {
      headers: await createHeadersAsync(),
      method: 'PUT',
      body: JSON.stringify(body)
  })
  .then(async (response) => {
    if (!response.ok) {
      throw await getErrorCode(response);
    }
    return response.json();
  })
  .catch((error) => {
    return {error: error};
  });
}

type DeleteMedicalInstitutionsRequest = {
  error?: string
};

export async function deleteMedicalInstitutionsAsync(id: string): Promise<DeleteMedicalInstitutionsRequest> {
  return fetch(`${BASE_URL}/medical_institutions/${id}`, {
    headers: await createHeadersWithNoContentAsync(),
    method: 'DELETE'
  })
  .then(async (response) => {
    if (!response.ok) {
      throw await getErrorCode(response);
    }

    return {};
  })
  .catch((error) => {
    return {error: error};
  });
}

type ListMedicalInstitutionsResult = {
  medicalInstitutions: MedicalInstitution[],
  error?: undefined;
} | {
  medicalInstitutions?: undefined;
  error: string
};

export async function listMedicalInstitutionsAsync(): Promise<ListMedicalInstitutionsResult> {
  return fetch(`${BASE_URL}/medical_institutions`, {
      headers: await createHeadersWithNoContentAsync(),
      method: 'GET'
    })
    .then(async (response) => {
      if (!response.ok) {
        throw await getErrorCode(response);
      }
      return response.json() as Promise<MedicalInstitution[]>;
    })
    .then((json) => {
      return {medicalInstitutions: json}
    })
    .catch((error) => {
      return {error: error};
  });
}