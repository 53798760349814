import {
  CertificateCardCollection
} from './ui-components';
import { useState, useEffect } from 'react';
import { Flex, Heading, Button, Loader } from "@aws-amplify/ui-react";
import Modal from 'react-modal';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Certificate, CertificateType, issueAndDownloadCertificateAsync, listCertificatesAsync, revokeCertificatesAsync } from './api/certificates';
import { getErrorMessage } from './constants';

function Certificates() {
  const { t } = useTranslation();
  const { id } = useParams();

  // 証明書発行API呼び出し中か？
  const [issuingCertificate, setIssuingCertificate] = useState(false);
  // 証明書発行ダイアログ表示中か？
  const [issueModalOpened, setIssueModalOpened] = useState(false);
  // 証明書失効ダイアログ表示中か？
  const [revokeModalOpened, setRevokeModalOpened] = useState(false);
  // 証明書一覧
  const [items, setItems] = useState<Certificate[]>();
  // 証明書一覧の読込中か？
  const [isLoading, setIsLoading] = useState(true);
  // 失効API呼び出し中か？
  const [revokingCertificate, setRevokingCertificate] = useState(false);
  // 削除対象の証明書
  const [selectedCertificate, setSelectedCertificate] = useState<string | undefined>();

  const issueCertificate = async (id: string, type: CertificateType) => {
    setIssuingCertificate(true);
    const response = await issueAndDownloadCertificateAsync(id, {type: type});
    if (response.error !== undefined) {
      alert(t(getErrorMessage(response.error)));
    }
    setIssuingCertificate(false);
  };

  const revokeCertificate = async (id: string, serialNumber: string) => {
    setRevokingCertificate(true);
    const response = await revokeCertificatesAsync(id, serialNumber);
    if (response.error !== undefined) {
      alert(t(getErrorMessage(response.error)));
    }
    setRevokingCertificate(false);
  }

  const refreshCertificates = async (id: string) => {
    setIsLoading(true);
    const response = await listCertificatesAsync(id);
    if (!response.certificates) {
      alert(t(getErrorMessage(response.error)));
    } else {
      setItems(response.certificates);
      setIsLoading(false);
    }
  }

  if (!id) {
    throw new Error("id is required.");
  }

  useEffect(() => {
    refreshCertificates(id);
  }, []);

  return (
    <Flex direction="column" alignItems="center">
      <Heading level={1}>{t('証明書一覧')}</Heading>
      <Modal
        isOpen={issueModalOpened}
        onRequestClose={
          () => {
            if (!issuingCertificate)
              setIssueModalOpened(false);
          }}
        style={{
          overlay: {},
          content: {
            top: '30%',
            left: '30%',
            right: 'auto',
            bottom: 'auto',
          }
        }}
      >
        <Flex direction="column" gap="3em">
          <Heading>{t('本当に証明書を発行しますか？')}</Heading>
          <Flex direction="row" gap="3em">
            <Button variation="primary" onClick={() => setIssueModalOpened(false)} isDisabled={issuingCertificate}>{t('キャンセル')}</Button>
            <Button variation="warning" onClick={async () => {
              await issueCertificate(id, CertificateType.Other);
              setIssueModalOpened(false);
              refreshCertificates(id);
            }}
              isLoading={issuingCertificate}>{t('発行する(Windows向け)')}</Button>
            <Button variation="warning" onClick={async () => {
              await issueCertificate(id, CertificateType.iOS);
              setIssueModalOpened(false);
              refreshCertificates(id);
            }}
              isLoading={issuingCertificate}>{t('発行する(iPad向け)')}</Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        isOpen={revokeModalOpened}
        onRequestClose={
          () => {
            if (!revokingCertificate)
              setRevokeModalOpened(false);
          }}
        style={{
          overlay: {},
          content: {
            top: '40%',
            left: '40%',
            right: 'auto',
            bottom: 'auto',
          }
        }}
      >
        <Flex direction="column" gap="3em">
          <Heading>{t('本当に以下の証明書を失効しますか？')}</Heading>
          <Heading>{selectedCertificate}</Heading>
          <Flex direction="row" gap="3em">
            <Button variation="primary" onClick={() => setRevokeModalOpened(false)} isDisabled={revokingCertificate}>{t('キャンセル')}</Button>
            <Button variation="destructive" onClick={async () => {
              await revokeCertificate(id, selectedCertificate!);
              setRevokeModalOpened(false);
              refreshCertificates(id);
            }}
              isDisabled={revokingCertificate}>{t('失効する')}</Button>
          </Flex>
        </Flex>
      </Modal>

      <Button variation="link" onClick={() => setIssueModalOpened(true)} disabled={issuingCertificate} isLoading={issuingCertificate}>
        {t('証明書の新規発行')}
      </Button>
      {
        isLoading
          ? <Loader size="large" />
          : <CertificateCardCollection items={items} overrideItems={({
            item
          }) => ({
            key: item.serialNumber,
            overrides: {
              Button: {
                onClick:
                  () => { setSelectedCertificate(item.serialNumber); setRevokeModalOpened(true); },
                isDisabled: item.revoked,
                children: item.revoked ? t('失効済') : t('失効')
              },
              SerialNumber: {
                children: <>{item.serialNumber}</>
              }
            }
          })} />
      }
    </Flex>
  );
}

export default Certificates;
