import {
    CreateMedicalSpecialtyForm
} from './ui-components';
import { useEffect, useState } from 'react';
import { Flex, Message } from "@aws-amplify/ui-react";
import { useParams, useNavigate } from "react-router-dom";
import React from 'react';
import { MedicalSpecialtyCreateRequest, MedicalSpecialtyCreateRequestType, createMedicalSpecialtiesAsync } from './api/medicalSpecialties';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function CreateMedicalSpecialty() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) {
    throw new Error("id is required.");
  }

  const [ formData, setFormData ] = useState<MedicalSpecialtyCreateRequestType>({name: ''});
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ submitting, setSubmitting ] = useState(false);

  const createAndNavigate = async () => {
    setSubmitting(true);
    const result = await createMedicalSpecialtiesAsync(id, {'name': formData.name});
    if (!result.error) {
      navigate('./../');
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  useEffect(() => {
    validate(formData);
  }, []);

  /** バリデーションを実行する。 */
  const validate = (data: MedicalSpecialtyCreateRequestType) => {
    const result = MedicalSpecialtyCreateRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map((x) => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   * 
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dataToValidate = formData;

    switch (e.target.id) {
      case 'name':
        setFormData({ name: e.target.value });
        dataToValidate.name = e.target.value;
        break;
    }

    validate(dataToValidate);
  };

  return (
    <Flex direction="column" alignItems="center">
      <CreateMedicalSpecialtyForm overrides={{
        Header: {
          children: t('診療科情報')
        },
        Name: {
          id: 'name',
          label: t('診療科名'),
          value: formData.name,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Button: {
          onClick: createAndNavigate,
          children: t('診療科の登録'),
          isDisabled: errors.length > 0 || submitting
        }
      }}/>
      {
        errors.length > 0
          ? errors.map((error: string, index: number) => <Message key={index} colorTheme="warning">{error}</Message>) 
          : <></>
      }
    </Flex>
  );
}

export default CreateMedicalSpecialty;