import './App.css';
import { Authenticator, Flex } from "@aws-amplify/ui-react";
import { Amplify, I18n } from "aws-amplify";
import { translations } from '@aws-amplify/ui';
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";

import {
  SaMDNavBarHeader
} from './ui-components';

import ListMedicalInstitutions from './MedicalInstitutionsList';
import ShowMedicalInstitution from './ShowMedicalInstitution';
import CreateMedicalInstitution from './CreateMedicalInstitution';
import CreateApprover from './CreateApprover';
import EditApprover from './EditApprover';
import EditMedicalInstitution from './EditMedicalInstitution';
import React from 'react';
import Certificates from './Certificates';
import { appConfig } from './constants';
import MedicalSpecialties from './MedicalSpecialties';
import CreateMedicalSpecialty from './CreateMedicalSpecialty';
import EditMedicalSpecialty from './EditMedicalSpecialty';
import { useTranslation } from 'react-i18next';

// TODO: 日本語化など https://qiita.com/ssugimoto/items/d0bc7540493499b6b154
I18n.putVocabularies(translations);
I18n.setLanguage('ja');

Amplify.configure({
  Auth: {
    region: appConfig.region,
    userPoolId: appConfig.userPoolId,
    userPoolWebClientId: appConfig.userPoolWebClientId,
  },
});

function NavBar(props: any) {
  const { t } = useTranslation();
  const navigation = useNavigate();

  return (
    <SaMDNavBarHeader overrides={{
      "SaMD Header": {
        children: t('SaMD 管理画面')
      },
      Button: {
        children: t('サインアウト'),
        onClick: props.signOut
      },
      TopButton: {
        onClick: () => navigation('/'),
        children: t('病院一覧')
      },
      CSVButton: {
        children: t('CSV出力')
      }
    }}/>
  );
}

function App() {
  return (
    <div className="App">
      <Authenticator hideSignUp={true}>
      {({ signOut, user }) => (
        <Flex gap="1rem" direction="column">
          <BrowserRouter>
            <NavBar signOut={signOut} />
            <Routes>
              <Route path="/" element={ <ListMedicalInstitutions /> } />
              <Route path="/medical_institutions/:id" element={ <ShowMedicalInstitution /> } />
              <Route path="/medical_institutions/new" element={ <CreateMedicalInstitution /> } />
              <Route path="/medical_institutions/:id/edit" element={ <EditMedicalInstitution /> } />
              <Route path="/medical_institutions/:id/approvers/new" element={ <CreateApprover /> } />
              <Route path="/medical_institutions/:id/approvers/:approverId/edit" element={ <EditApprover /> } />
              <Route path="/medical_institutions/:id/certificates" element={ <Certificates /> } />
              <Route path="/medical_institutions/:id/medical_specialties" element={ <MedicalSpecialties /> } />
              <Route path="/medical_institutions/:id/medical_specialties/new" element={ <CreateMedicalSpecialty /> } />
              <Route path="/medical_institutions/:id/medical_specialties/:medicalSpecialtyId/edit" element={ <EditMedicalSpecialty /> } />
            </Routes>
          </BrowserRouter>
        </Flex>
        
      )}
    </Authenticator>
    </div>
  );
}

export default App;
