import {
    CreateApproverForm
} from './ui-components';
import { useState, useEffect } from 'react';
import { Flex, Loader, Message } from "@aws-amplify/ui-react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import React from 'react';
import { MedicalSpecialty, getMedicalSpecialtiesAsync } from './api/medicalSpecialties';
import { ApproverCreateRequest, CreateApproverRequestType, createApproverAsync } from './api/approvers';
import { useTranslation } from 'react-i18next';
import { MedicalInstitution, getMedicalInstitutionAsync } from './api/medicalInstitutions';
import { getErrorMessage } from './constants';

function CreateApprover() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ medicalInstitution, setMedicalInstitution ] = useState<MedicalInstitution>();
  const [ medicalSpecialties, setMedicalSpecialties] = useState<MedicalSpecialty[]>([]);
  const [ formData, setFormData ] = useState<CreateApproverRequestType>({name: '', mail: '', mailConfirm: '', medicalSpecialty: undefined});
  const [ loading, setLoading ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  if (!id) {
    throw new Error("id is required.");
  }

  // 遷移時に診療科名一覧を取得する。
  useEffect(() => {
    setLoading(true);

    // stateが取得できるときは承認者名、メールアドレスを取得して設定する。そうでなければAPIを使って取得してくる。
    // URLで直接アクセスしたときなどはstateを取得できない
    if (!state) {
      getMedicalInstitutionAsync(id).then((response) => {
        if (!response.medicalInstitution) {
          alert(t(getErrorMessage(response.error)));
          navigate('./../../../'); // エラー時、医療機関画面に戻る
        } else {
          setMedicalInstitution(response.medicalInstitution);
        }
      });
    } else {
      const { medicalInstitution } = state;
      setMedicalInstitution(medicalInstitution);
    }

    getMedicalSpecialtiesAsync(id).then((response) => {
      if (!response.medicalSpecialties) {
        alert(t(getErrorMessage(response.error)));
      } else {
        setMedicalSpecialties(response.medicalSpecialties);
        setLoading(false);
      }
    });

    validate(formData);
  }, []);

  /** 承認者を作成し、成功したら医療機関画面へ遷移する。 */
  const createAndNavigate = async () => {
    setSubmitting(true);
    const response = await createApproverAsync(id, {'name': formData.name, 'mailAddress': formData.mail, 'medicalSpecialty': formData.medicalSpecialty});
    if (!response.error) {
      navigate('./../../', { state: { medicalInstitution: medicalInstitution } });
    } else {
      alert(t(getErrorMessage(response.error)));
      setSubmitting(false);
    }
  };

  /** バリデーションを実行する。 */
  const validate = (data: CreateApproverRequestType) => {
    const result = ApproverCreateRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map((x) => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   * 
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    let dataToValidate = formData;

    switch(e.target.id) {
      case 'name':
        setFormData({...formData, name: e.target.value});
        dataToValidate.name = e.target.value;
        break;
      case 'mail':
        setFormData({...formData, mail: e.target.value});
        dataToValidate.mail = e.target.value;
        break;
      case 'mail-confirm':
        setFormData({...formData, mailConfirm: e.target.value});
        dataToValidate.mailConfirm = e.target.value;
        break;
      case 'medical-specialty':
        const selected = medicalSpecialties.find((v) => v.name === e.target.value);
        setFormData({...formData, medicalSpecialty: selected?.id});
        dataToValidate.medicalSpecialty = selected?.id;
        break;
    }

    validate(dataToValidate);
  };

  return (
    <Flex direction="column" alignItems="center">
      {
        loading
        ?
          <Loader size="large" />
        :
          <></>
      }  
      <CreateApproverForm overrides={{
        Header: {
          children: t('承認者情報')
        },
        Name: {
          id: 'name',
          value: formData.name,
          label: t('承認者名'),
          placeholder: t('承認 太郎'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
          }
        },
        MailAddress: {
          id: 'mail',
          value: formData.mail,
          label: t('承認者メールアドレス'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
          }
        },
        MailAddressConfirm: {
          id: 'mail-confirm',
          value: formData.mailConfirm,
          label: t('承認者メールアドレス(確認)'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
          }
        },
        MedicalSpecialty: {
          id: 'medical-specialty',
          label: t('診療科'),
          options: medicalSpecialties.map((v) => v.name),
          onChange: (e: React.ChangeEvent<HTMLSelectElement> & React.ChangeEvent<HTMLDivElement>) => {
            onChange(e);
          }
        },
        Button: {
          onClick: () => createAndNavigate(),
          children: t('承認者情報の登録'),
          isDisabled: errors.length > 0 || submitting
        }
      }}/>
      {
        errors.length > 0
          ? errors.map((error: string, index: number) => <Message key={index} colorTheme="warning">{error}</Message>) 
          : <></>
      }
    </Flex>
  );
}

export default CreateApprover;