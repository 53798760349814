import {
    CreateMedicalSpecialtyForm
} from './ui-components';
import { useState, useEffect } from 'react';
import { Button, Flex, Heading, Loader, Message } from "@aws-amplify/ui-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import React from 'react';
import Modal from 'react-modal';

import { MedicalSpecialtyUpdateRequest, MedicalSpecialtyUpdateRequestType, UpdateMedicalSpecialtyRequest, deleteMedicalSpecialtyAsync, getMedicalSpecialtyAsync, updateMedicalSpecialtiesAsync } from './api/medicalSpecialties';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from './constants';

function EditMedicalSpecialty() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id, medicalSpecialtyId } = useParams();

  const [ formData, setFormData ] = useState<UpdateMedicalSpecialtyRequest>({name: ''});
  const [ errors, setErrors ] = useState<string[]>([]);
  const [ modalOpened, setModalOpened ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  if (!id || !medicalSpecialtyId) {
    throw new Error("id and medicalSpecialtyId are required.");
  }

  const updateAndNavigate = async () => {
    setSubmitting(true);
    const result = await updateMedicalSpecialtiesAsync(id, Number(medicalSpecialtyId), {'name': formData.name});
    if (!result.error) {
      navigate('./../../');
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  const deleteAndNavigate = async () => {
    setSubmitting(true);
    const result = await deleteMedicalSpecialtyAsync(id, Number(medicalSpecialtyId));
    if (!result.error) {
      navigate('./../../');
    } else {
      alert(t(getErrorMessage(result.error)));
      setSubmitting(false);
    }
  };

  // stateが取得できる場合はそこから診療科情報を取得、できない場合はgetMedicalSpecialtyAsyncで取得してくる。
  // URLで直接アクセスしたときなどはstateを取得できない。
  useEffect(() => {
    if (!state) {
      setLoading(true);
      getMedicalSpecialtyAsync(id, Number(medicalSpecialtyId)).then((response) => {
        if (!response.medicalSpecialty) {
          alert(t(getErrorMessage(response.error)));
          navigate('./../../');
        } else {
          setFormData({name: response.medicalSpecialty.name});
          setLoading(false);
        }
      });
    } else {
      const { medicalInstitution } = state;
      setFormData({name: medicalInstitution.name});
    }
  }, []);

  /** バリデーションを実行する。 */
  const validate = (data: MedicalSpecialtyUpdateRequestType) => {
    const result = MedicalSpecialtyUpdateRequest.safeParse(data);

    if (result.success) {
      setErrors([]);
    } else {
      const message = result.error.errors.map((x) => x.message);
      setErrors(message);
    }
  };

  /** フォームの内容変更時に呼び出すハンドラー
   * 
   * 状態を変更し、バリデーションを実行する。*/
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let dataToValidate = formData;

    switch (e.target.id) {
      case 'name':
        setFormData({name: e.target.value});
        dataToValidate.name = e.target.value;
        break;
    }

    validate(dataToValidate);
  };

  return (
    <Flex direction="column" alignItems="center">
      {
        loading
        ?
          <Loader size="large" />
        :
          <></>
      }
      <Modal
        isOpen={modalOpened}
        onRequestClose={() => setModalOpened(false)}
        style={{
          overlay: {},
          content: {
            top: '40%',
            left: '40%',
            right: 'auto',
            bottom: 'auto',
          }
        }}
        >
        <Flex direction="column" gap="3em">
          <Heading>{t('「name」を本当に削除しますか？', {name: formData.name})}</Heading>
          <Flex direction="row" gap="3em">
            <Button variation="primary" onClick={() => setModalOpened(false)}>{t('キャンセル')}</Button>
            <Button variation="destructive" onClick={deleteAndNavigate}>{t('削除する')}</Button>
          </Flex>
        </Flex>
      </Modal>
      <Button variation='primary'
        style={{ marginLeft: "auto" }}
        colorTheme='error'
        disabled={loading || submitting}
        onClick={() => setModalOpened(true)}>{t('診療科を削除する')}</Button>
      <CreateMedicalSpecialtyForm overrides={{
        Header: {
          children: t('診療科情報')
        },
        Name: {
          id: 'name',
          value: formData.name,
          label: t('診療科名'),
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e)
        },
        Button: {
          onClick: updateAndNavigate,
          children: t('診療科の登録'),
          isDisabled: loading || errors.length > 0 || submitting
        }
      }}/>
      {
        errors.length > 0
          ? errors.map((error: string, index: number) => <Message key={index} colorTheme="warning">{error}</Message>)
          : <></>
      }
    </Flex>
  );
}

export default EditMedicalSpecialty;